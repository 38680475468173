import PropTypes from 'prop-types';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

function SwitchButton({
  checked,
  handleChange,
  label,
  name,
  labelPlacement,
  disabled = false,
}) {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked ?? false}
          onChange={handleChange}
          name={name}
          disabled={disabled}
        />
      }
      label={label}
      labelPlacement={labelPlacement || 'end'}
    />
  );
}

SwitchButton.propTypes = {
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  labelPlacement: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SwitchButton;
